<template>
  <div>
    <!-- <router-link to="/sendInfo">发布信息页面</router-link> -->
    <router-view></router-view>
  </div>
</template>

<script>
// import "@/styles/css/animate.css"
// import "@/styles/css/bootstrap-xinqi.css"
// import "@/styles/css/animate.css"
// import "@/styles/css/bootstrap.min.css"
// import commonPage from "@/components/commonPage.vue"
// import {
//   Form,
//   FormItem,
//   Input,
//   DatePicker,
//   Button,
//   Modal,
//   RadioGroup,
//   Cascader,
// } from "@opentiny/vue"
// import {
//   Form,
//   FormItem,
//   Input,
//   DatePicker,
//   Button,
//   Modal,
//   RadioGroup,
//   Cascader,
// } from "@opentiny/vue"
export default {
  components: {
    // commonPage,
  },
  data() {
    return {
      rules: {
        radio: [{ required: true, message: "必填", trigger: "blur" }],
        users: [
          { required: true, message: "必填", trigger: "blur" },
          { min: 2, max: 11, message: "长度必须不小于2", trigger: "change" },
        ],
        datepicker: { type: "date" },
        url: { type: "url" },
        email: { type: "email" },
        cascader: [{ required: true, message: "必填", trigger: "blur" }],
      },
    }
  },
  methods: {
    // 跳转到发布信息页面
    links() {},
    clearFormValid() {
      this.$refs.ruleFormRef.clearValidate()
    },
    btn() {
      console.log("456")
    },
    handleSubmit() {
      this.$refs.ruleFormRef.validate((valid) => {
        if (valid) {
          Modal.alert("提交成功")
        }
      })
    },
  },
}
</script>

<style>
.demo-form {
  width: 380px;
}
</style>
