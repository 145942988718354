import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/styles/css/win.css'
import "@/styles/css/animate.css"
import "@/styles/css/bootstrap-xinqi.css"
import '@/styles/css/service.css'
import "@/styles/css/bootstrap.min.css"
import '@/fonts/iconfont.css'
import '@/styles/css/scroll.css'
import '@/styles/css/index.css'
import '@/styles/css/goodsDetails.css'
import '@/styles/css/lyh-css.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
