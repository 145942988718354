import VueRouter from 'vue-router'
import Vue from 'vue'
// 把路由全部改为懒加载模式,目的是把整个大的js文件分成多个小体积的js文件，缩短首屏加载缓慢的问题
const IndexPage = () => import('@/views/IndexPage.vue')
const SendInfo = () => import('@/views/SendInfo.vue')
const ServicePage = () => import('@/views/ServicePage.vue')
const PHZPPage = () => import('@/views/PHZPPage.vue')
const AdPage = () => import('@/views/AdPage.vue')
const GoodsPage = () => import('@/views/GoodsPage.vue')
const GoodsDetailsPage = () => import('@/views/GoodsDetailsPage.vue')
const GoodsDetailsPage2 = () => import('@/views/GoodsDetailsPage_2.vue')
const Login = () => import('@/views/Login.vue')
const Register = () => import('@/views/Register.vue')
const HzServicePageDetails = () => import('@/views/HzServicePageDetails.vue')
const PhzPageDetails = () => import('@/views/PhzPageDetails.vue')
const AdPageDetails = () => import('@/views/AdPageDetails.vue')
Vue.use(VueRouter)


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    // 首页
    path: '/',
    name: 'indexPage',
    component: IndexPage,
  },
  {
    // 信息发送页面
    path: '/sendInfo',
    name: 'sendInfo',
    component: SendInfo,
  },
  {
    // 会展服务页面
    path: '/servicePage',
    name: 'service',
    component: ServicePage,
  },
  {
    // 会展服务详情页面
    path: '/HzServicePageDetails/:obj',
    name: 'HzServicePageDetails',
    component: HzServicePageDetails,
  },
  {
    // 商品供应页面
    path: '/goodsPage',
    name: 'goodsPage',
    component: GoodsPage,
  },
  {
    // 喷绘招牌页面
    path: '/phzpPage',
    name: 'phzpPage',
    component: PHZPPage,
  },
  {
    // 喷绘招牌详情页面
    path: '/PhzPageDetails/:obj',
    name: 'PhzPageDetails',
    component: PhzPageDetails,
  },

  {
    // 广告页面
    path: '/adPage',
    name: 'adPage',
    component: AdPage,
  },
  {
    // 广告详情页面
    path: '/adPageDetails/:obj',
    name: 'adPageDetails',
    component: AdPageDetails,
  },
  {
    // 商品详情页面
    path: '/goodsDetailsPage/:obj',
    name: 'goodsDetailsPage',
    component: GoodsDetailsPage,
  },
  {
    // 商品详情页面2
    path: '/goodsDetailsPage2/:obj',
    name: 'goodsDetailsPage2',
    component: GoodsDetailsPage2,
  },

  {
    // 登录页面
    path: '/login',
    name: 'LoginPage',
    component: Login
  },
  {
    // 注册页面
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '*',
    redirect: '/',
  }
]   //route对象（填写路由）
const router = new VueRouter({ mode: 'hash', routes })
// VueRouter.$addRoutes = params => {
//   router.matcher = new Router({
//     routes: router.options.routes
//     // 关键代码
//   }).matcher;
//   VueRouter.addRoutes(params);
// };
export default router;
